import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDw1VrTJTAomPFFsujbiG7XHBVTue2XASo",
  authDomain: "clicks-70152.firebaseapp.com",
  projectId: "clicks-70152",
  storageBucket: "clicks-70152.appspot.com",
  messagingSenderId: "501818651134",
  appId: "1:501818651134:web:b58bedcf3be2add3c990af",
};

// init firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();
// const timestamp = serverTimestamp();

export { db, serverTimestamp };
