<template>
  <div class="containerSite" id="contactSite"><h1>Contact</h1></div>

  <router-link :to="{ name: 'Start' }" class="close">×</router-link>
</template>

<script>
export default {};
</script>

<style>
body {
  padding: 0;
}

#contactSite.containerSite {
  background-color: var(--violet);
  width: 100%;
  min-height: 100vh;
}
.containerSite h1 {
  padding-top: 30px;
  font-size: 2rem;
  font-weight: 600;
  color: var(--background);
}
.close {
  text-decoration: none;
  color: var(--background);
  /* border: 2px solid var(--background); */
}
</style>
