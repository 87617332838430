<!--        1. dodac (x) jak w liczbach
            2. popawic timer, 
    3. dolozyc instrukcje instalacji na iphone i innych
            4. poprawic wyswietlanie na telefonie
    5. sprawdzic android
            5. zrobic Hight Score
            6. laczenie z baza
            7. niech nie znika nieprawidlowo kliknieta liczba
            8. przyklad TIMERA: 
            https://css-tricks.com/how-to-create-an-animated-countdown-timer-with-html-css-and-javascript/
            https://codepen.io/nw/pen/zvQVWM
            https://codepen.io/aneeven/pen/WQYwNd
    9. za blednie klikniecy -1 sekunda i zmiana na chwile na czerwono...
            10. mierzenie czasu do kazdego kliniecia
    11. bouble wrap sound
    12. vibrowanie po kliknieciu
    13. timer zrobic  watcheffect po zmiennej weglug czasu
    14. caly kontener na absolucie czy cos zeby sie nie skrollowal jak teraz
    15. personal High Score
    16. You are in the TOP!
    17. zablokowac ctr+F
    18. usunac blad ze jak sie nic nie zrobi to sie otrzymuje '-1' punktow
    19. wynik czasowy tez odpowiednio pokolorowac, score rowniez
    20. on dbclick na logo robi sie fuck
    21. wiecej easter egow, jak klikniesz w tlo 11 razy to sie cos wydarzy, kolory sie zmienia czy co
    22. placeholder animation https://codepen.io/nurulishlah/pen/beEGVEhttps://codepen.io/nurulishlah/pen/beEGVE
    INFO jakie informacje zbierac do kazdej gry 
    🟩 helpUsed.value — ilosc uzytej pomocy
    🟩 arrayOne.value — array czasow pomiedzy kliknieciami /// to jest tablica z gra a nie czasy
    🟩 wynikNum.value — wynik -- score
    🟩 BRAK - uid usera
    🟩 timestamp gry -- createdAt
    23. zrobic zmieniajacy sie title po kazdym kliknieciu, 99click left.. 98click.. .....1click  https://vueuse.org/core/useTitle/
            25. DONE wygenerowanie UID https://github.com/lukeed/uid
            24. cookies js - https://github.com/js-cookie/js-cookie w cookie umiescic uid i nickname jak bedzie rekord i poda nicnkame, pamietaj name powinien byc unikatory czyli przykladowo 99clicksUid, value "wygenerowane UID"
    26. po wejscu na strone sprawdzam czy jest cookie, jak jest to zmieniam zmienna isCookie na true, jak nie ma to po zrobieniu rekordu zapytam o nazwe uzytkownika i ustwie cookie
    27 jak jest cookie to pobieram dla danego UID jego TOP 10
    28. share na mobilu - https://vueuse.org/core/useShare/
    29. https://vueuse.org/core/useOnline/ sprawdzaj czy jest online jesli nie to pobierz dane z cookiesow
    30. AAAAAA mzoe dane o danym uzytkowniku przechowywac w local storage, https://vueuse.org/core/useStorage/
    31. jezeli ktor ma rekord na poziomie 5-10 to mozna mu napisac cos w stylu stac cie na wiecej... i te motywatory pojawiajace sie jak wiadomosc z telefonu
    dorobic checkIsTop10Personal i checkIsTheBestPersonal

    32. zastanowic sie jak pobierac nicnmamy do globalnego rankingu, czy tak jak zrobilem teraz z collection idNickname czy jednak lepiej bedzie nick zapisywac bezposrednio do kazdego rekordu?
-->
<template>
  <div class="startScreen" v-if="!isPlaying">
    <div class="title">
      <img
        class="icon"
        src="@/assets/99-icon.svg"
        v-on:dblclick="changeIcon"
        v-on:doubletap="changeIcon"
        v-if="isIcon"
      />
      <img
        class="icon"
        src="@/assets/99-icon-fcuk.svg"
        v-on:dblclick="changeIcon"
        v-on:doubletap="changeIcon"
        v-if="!isIcon"
        alt="99clicks"
      />

      <img class="icon" src="@/assets/99clicks-logo.svg" />

      <h1>Your daily concentration challenge</h1>
      <div class="instruction">
        <div class="numTimesContainer">
          <h2>How to play</h2>
          <p>Just click all numbers from 0–99</p>
          <p>You've got 1 minute</p>
          <p>Good luck!</p>
        </div>

        <button @click="start" class="startMain">start game</button>
        <div
          class="highscoresSection personal"
          v-if="scorePersonal && !isFirstGame"
        >
          <div class="highscores">
            <h2>🎯 <br />My Top10</h2>

            <div class="numTimesContainer" v-if="true">
              <div class="numTimes">
                <ol v-if="!showMoreMy">
                  <li
                    v-for="doc in scorePersonal.slice(0, 5)"
                    :key="doc.createdAt"
                  >
                    <div>
                      <button
                        class="num"
                        :class="{
                          color5: !(doc.score % 5),
                          color4: doc.score == 4 || doc.score % 5 === 4,
                          color3: doc.score == 3 || doc.score % 5 === 3,
                          color2: doc.score == 2 || doc.score % 5 === 2,
                          color1: doc.score == 1 || doc.score % 5 === 1,
                        }"
                      >
                        {{ doc.score }}
                      </button>
                    </div>
                    <!-- <div class="nick">{{ doc.nickname }}</div> -->
                    <div class="score-text-no-nick">
                      <div class="time">{{ parseDate(doc.createdAt) }} ago</div>
                    </div>
                  </li>
                </ol>
                <ol v-if="showMoreMy">
                  <li v-for="doc in scorePersonal" :key="doc.createdAt">
                    <div>
                      <button
                        class="num"
                        :class="{
                          color5: !(doc.score % 5),
                          color4: doc.score == 4 || doc.score % 5 === 4,
                          color3: doc.score == 3 || doc.score % 5 === 3,
                          color2: doc.score == 2 || doc.score % 5 === 2,
                          color1: doc.score == 1 || doc.score % 5 === 1,
                        }"
                      >
                        {{ doc.score }}
                      </button>
                    </div>
                    <div class="score-text-no-nick">
                      <!-- <div class="nick">{{ doc.nickname }}</div> -->
                      <div class="time">{{ parseDate(doc.createdAt) }} ago</div>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
            <a
              class="bottomMenu seeMore"
              v-if="!showMoreMy"
              @click="handleShowMore('personal')"
              ><span>see more</span></a
            >
            <a
              class="bottomMenu seeMore"
              v-if="showMoreMy"
              @click="handleShowMore('personal')"
              ><span>see less</span></a
            >
          </div>
        </div>
        <div class="highscoresSection">
          <div class="highscores">
            <h2>🏆 Top 10</h2>

            <div class="numTimesContainer">
              <div class="numTimes">
                <ol v-if="!showMoreGlobal && formatedHighScore">
                  <li
                    v-for="doc in formatedHighScore.slice(0, 5)"
                    :key="doc.id"
                  >
                    <div>
                      <button
                        class="num"
                        :class="{
                          color5: !(doc.score % 5),
                          color4: doc.score == 4 || doc.score % 5 === 4,
                          color3: doc.score == 3 || doc.score % 5 === 3,
                          color2: doc.score == 2 || doc.score % 5 === 2,
                          color1: doc.score == 1 || doc.score % 5 === 1,
                        }"
                      >
                        {{ doc.score }}
                      </button>
                    </div>
                    <div class="score-text-nick" v-if="doc.nickname">
                      <div class="nick">{{ doc.nickname }}</div>
                      <div class="time score-text-createdat-nick">
                        {{ doc.createdAt }} ago
                      </div>
                    </div>

                    <div class="score-text-no-nick" v-if="!doc.nickname">
                      <div class="nick">{{ doc.nickname }}</div>
                      <div class="time score-text-createdat-no-nick">
                        {{ doc.createdAt }} ago
                      </div>
                    </div>
                  </li>
                </ol>
                <ol v-if="showMoreGlobal && formatedHighScore">
                  <li v-for="doc in formatedHighScore" :key="doc.id">
                    <button
                      class="num"
                      :class="{
                        color5: !(doc.score % 5),
                        color4: doc.score == 4 || doc.score % 5 === 4,
                        color3: doc.score == 3 || doc.score % 5 === 3,
                        color2: doc.score == 2 || doc.score % 5 === 2,
                        color1: doc.score == 1 || doc.score % 5 === 1,
                      }"
                    >
                      {{ doc.score }}
                    </button>
                    <div class="score-text-nick" v-if="doc.nickname">
                      <div class="nick">{{ doc.nickname }}</div>
                      <div class="time score-text-createdat-nick">
                        {{ doc.createdAt }} ago
                      </div>
                    </div>

                    <div class="score-text-no-nick" v-if="!doc.nickname">
                      <div class="nick">{{ doc.nickname }}</div>
                      <div class="time score-text-createdat-no-nick">
                        {{ doc.createdAt }} ago
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
            <a
              class="bottomMenu seeMore"
              v-if="!showMoreGlobal"
              @click="handleShowMore('global')"
              ><span>see more</span></a
            >
            <a
              class="bottomMenu seeMore"
              v-if="showMoreGlobal"
              @click="handleShowMore('global')"
              ><span>see less</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="qrcodeSection" v-if="!isPlaying">

    <h1>Share link</h1>
    <div class="qrcode"><img src="@/assets/qr-code-www.svg" /></div>
  </div>-->

  <!-- bottom menu -->
  <div class="bottomMenu" v-if="!isPlaying">
    <a @click="startShare" class="bottomMenu clickandshare"
      ><span><u>click & share</u></span></a
    >
    <a
      href="https://www.buymeacoffee.com/99clicks"
      target="blank"
      class="bottomMenu buymeacoffee"
      ><span>do you like it? :)<br /><u>buy me a ☕</u></span></a
    >
    <!-- <a href="#" class="bottomMenu about99"><span>about</span></a> -->
    <router-link :to="{ name: 'About' }" class="bottomMenu about99"
      ><span>about</span></router-link
    >
    <router-link :to="{ name: 'Contact' }" class="bottomMenu contactMe"
      ><span>contact me</span></router-link
    >

    <a class="bottomMenu withLove"><span>made with ❤️ to simplicity</span></a>
  </div>
  <!-- NEW RECORD -->
  <!-- <div class="newRecord" v-if="isTop10 || isTop1"> -->

  <!-- Cover blocker -->
  <div class="cover" v-if="!timeleft"></div>

  <!-- enterNicname -->

  <div class="wynik enterNickname" v-if="showEnterNickname">
    <div class="wynik-main-container">
      <div class="logo" v-if="showResult">99clicks</div>

      <!-- <button class="saveHighScore" @click="saveHighScore">Save score</button> -->

      <div v-if="!isNickname">
        <h1 class="main-header">😀</h1>

        <input
          type="text"
          v-model="nickname"
          placeholder="Enter your nickname"
          maxlength="10"
        /><br />
        <p>{{ 10 - nickname.length }} characters left</p>
      </div>
    </div>
  </div>

  <!-- wynik/ stats -->

  <div class="wynik" v-if="showResult">
    <div class="wynik-main-container">
      <div class="logo" v-if="showResult">99clicks</div>
      <div class="numWynik">
        <div>
          <div class="finalScore" v-if="wynikNum < 0">0</div>
          <div
            :class="{
              color5: !(wynikNum % 5),
              color4: wynikNum == 4 || wynikNum % 5 === 4,
              color3: wynikNum == 3 || wynikNum % 5 === 3,
              color2: wynikNum == 2 || wynikNum % 5 === 2,
              color1: wynikNum == 1 || wynikNum % 5 === 1,
            }"
            class="finalScore"
            v-if="wynikNum > 0"
          >
            {{ wynikNum }}
          </div>

          <div class="finalScoreLabel">score</div>
        </div>
        <div class="congratulations" v-if="isTop10 && !isTop1">
          🏆<br />Cogratulations!<br />You are in TOP 10
        </div>
        <div class="congratulations" v-if="isTop1">
          🏆<br />The highest score!<br />You are THE BEST!
        </div>
        <div class="congratulationsPersonal" v-if="isTheBestPersonal">
          🎯<br />Your personal<br />highest score!<br /><br />Good Job!
        </div>
      </div>
      <div class="seeMoreContainer">
        <a
          href="#scoreDetails"
          class="bottomMenu seeMore"
          :class="{
            color5Text: !(wynikNum % 5),
            color4Text: wynikNum == 4 || wynikNum % 5 === 4,
            color3Text: wynikNum == 3 || wynikNum % 5 === 3,
            color2Text: wynikNum == 2 || wynikNum % 5 === 2,
            color1Text: wynikNum == 1 || wynikNum % 5 === 1,
          }"
          >see details</a
        >
      </div>
    </div>

    <!-- numery + czasy klikniec -->
    <div class="numTimes" v-if="showResult" id="scoreDetails">
      <div class="numTimesContainer">
        <ol>
          <li v-for="(item, index) in timerNumCollection" :key="index">
            <button
              class="num"
              :class="{
                color5: !(index % 5),
                color4: index == 4 || index % 5 === 4,
                color3: index == 3 || index % 5 === 3,
                color2: index == 2 || index % 5 === 2,
                color1: index == 1 || index % 5 === 1,
              }"
            >
              {{ index }}
            </button>
            <div class="time">{{ item / 1000 }} sec</div>
          </li>
        </ol>
      </div>
    </div>
  </div>

  <!-- Logo -->
  <!-- <div class="logo" v-if="isPlaying">99clicks</div> -->

  <!-- Timer animation -->
  <div class="timerLineContainer" v-if="timeleft && isPlaying">
    <div class="timerLine"></div>
  </div>
  <!-- <img class="icon" src="@/assets/99clicks-logo.svg" v-if="isPlaying" /> -->
  <!-- Container   -->
  <div class="mainContainer">
    <div class="container">
      <button
        v-if="arrayOneRadom"
        v-for="element in arrayOneRadom"
        :key="element"
        class="element"
        :disabled="!timeleft"
        :class="{
          //  marked: isLastClicked, // oznaczony nie znaleziony
          color5: !(element % 5),
          color4: element == 4 || element % 5 === 4,
          color3: element == 3 || element % 5 === 3,
          color2: element == 2 || element % 5 === 2,
          color1: element == 1 || element % 5 === 1,
          hiddensquare: timeleft == 0 && element > arrayOneHundered[0],

          //
        }"
        @click="checkIsCorrect(element)"
        :id="element"
      >
        {{ element }}
      </button>
    </div>
    <div class="helpButtonsContainer" v-if="isPlaying">
      <div></div>
      <button
        class="element highlighted"
        :class="{ highlighted: !isClicked, hiddensquare: isClicked }"
        id="help1"
        @click="handleHelpBtn('help1')"
      >
        ?
      </button>
      <button
        class="element highlighted"
        id="help2"
        @click="handleHelpBtn('help2')"
      >
        ?
      </button>
      <button
        class="element highlighted"
        id="help3"
        @click="handleHelpBtn('help3')"
      >
        ?
      </button>
      <div></div>
    </div>
  </div>

  <!-- bottom elements -->
  <div class="timer" v-if="isPlaying">
    <div v-if="timeleft">
      <a @click="startAgain" class="close closegame">×</a>
    </div>
    <div v-else>
      <!-- <a @click="startAgain" class="link" v-if="!showResult">play again</a> -->

      <button
        v-if="!showResult && !isNickname && showEnterNickname"
        class="startMain"
        :disabled="!nickname"
        @click="handleSaveNickname(), (showEnterNickname = false)"
      >
        next
      </button>

      <button
        v-if="!showResult && isNickname"
        @click="
          (showResult = true),
            checkIsTop10(),
            checkIsTop1(),
            checkIsTheBestPersonal()
        "
        class="start"
      >
        show result
      </button>

      <button
        v-if="!showResult && !isNickname && !showEnterNickname"
        @click="showEnterNickname = true"
        class="start"
        :class="{
          color5Text: !(wynikNum % 5),
          color4Text: wynikNum == 4 || wynikNum % 5 === 4,
          color3Text: wynikNum == 3 || wynikNum % 5 === 3,
          color2Text: wynikNum == 2 || wynikNum % 5 === 2,
          color1Text: wynikNum == 1 || wynikNum % 5 === 1,
        }"
      >
        next
      </button>

      <button
        @click="startAgain"
        class="start"
        v-if="showResult"
        :class="{
          color5Text: !(wynikNum % 5),
          color4Text: wynikNum == 4 || wynikNum % 5 === 4,
          color3Text: wynikNum == 3 || wynikNum % 5 === 3,
          color2Text: wynikNum == 2 || wynikNum % 5 === 2,
          color1Text: wynikNum == 1 || wynikNum % 5 === 1,
        }"
      >
        next
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed, watchEffect, onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";

import { formatDistanceToNow, parse } from "date-fns";
import { uid } from "uid";

import getCollection from "@/composables/getCollection";
import getCollectionPersonal from "@/composables/getCollectionPersonal";

//Firebase imports
import { db, serverTimestamp } from "../firebase/config";
import { addDoc, collection, setDoc, doc } from "firebase/firestore";
import { useShare } from "@vueuse/core";

// import { query, orderBy, limit } from "firebase/firestore";

// const q = query(citiesRef, orderBy("name"), limit(3));

export default {
  name: "Start",
  components: {
    //
  },

  setup(props) {
    const router = useRouter();
    const { share, isSupported } = useShare();
    const databaseName = ref("highscore"); // TEST

    const arrayOne = ref([]);
    const arrayOneHundered = ref([]);
    const arrayOneRadom = ref([]);
    const timeleft = ref(null);
    const isPlaying = ref(false);

    const isLastClicked = ref(false);
    const isDisabled = ref(false);
    const wrongClicks = ref("");
    const wynikNum = ref(null);

    // const isNewRecord = ref(false);

    const timerNum = ref(null);
    const reactionTime = ref(null);
    const timerNumCollection = ref([]);

    const showResult = ref(false);
    const showEnterNickname = ref(false);
    const top10highestScoreUpTodate = ref(null);
    const top1highestScoreUpTodate = ref(null);
    const theBestPersonal = ref(null);

    const isTop10 = ref(false);
    const isTop1 = ref(false);
    const isTheBestPersonal = ref(false);

    const isIcon = ref(true);
    const showMoreMy = ref(false);
    const showMoreGlobal = ref(false);
    const isClicked = ref(false);
    const helpUsed = ref(0);
    const nickname = ref("");
    const isNickname = ref(false);
    const clicksUID = ref("");
    const isUid = ref(false);
    const scorePersonal = ref([]);
    // const sortedScorePersonal = ref([]);
    const highScorePersonal = ref(null);
    const isFirstGame = ref(true);

    // let formatedHighScorePersonal = ref("");

    onMounted(() => {
      // console.log("onBeforeMount");
      createUID();
      checkIsUserData();
    });

    const createUID = () => {
      const UID = uid(25);
      clicksUID.value = UID;
      isUid.value = true;
    };

    const checkIsUserData = () => {
      if (localStorage.getItem("clicksUID")) {
        isUid.value = true;
        clicksUID.value = localStorage.getItem("clicksUID");
        console.log(clicksUID.value);
      } else {
        createUID();
        localStorage.setItem("clicksUID", clicksUID.value);
        isUid.value = true;
      }

      if (localStorage.getItem("clicksNICK")) {
        isNickname.value = true;
        nickname.value = localStorage.getItem("clicksNICK");
      } else {
        nickname.value = "";
      }

      if (localStorage.getItem("clicksSCORE")) {
        scorePersonal.value = JSON.parse(localStorage.getItem("clicksSCORE"));
        if (scorePersonal.value) {
          scorePersonal.value.sort(comparePoints);
          scorePersonal.value = scorePersonal.value.slice(0, 10);
        }
        console.log(scorePersonal.value);
        isFirstGame.value = false;
      } else {
        // localStorage.setItem("clicksSCORE", "{}");
        // scorePersonal.value = JSON.parse(localStorage.getItem("clicksSCORE"));
        isFirstGame.value = true;
      }
    };

    function comparePoints(a, b) {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    }

    const { documents: highScore } = getCollection(
      databaseName.value,
      "score",
      "10"
    );

    const formatedHighScore = computed(() => {
      if (highScore.value) {
        return highScore.value.map((doc) => {
          let time = formatDistanceToNow(doc.createdAt.toDate());
          return { ...doc, createdAt: time };
        });
      }
    });

    const wait = function (seconds) {
      return new Promise(function (resolve) {
        setTimeout(resolve, seconds * 1000);
      });
    };

    function shuffle(array) {
      let i = array.length,
        j = 0,
        temp;

      while (i--) {
        j = Math.floor(Math.random() * (i + 1));

        // swap randomly chosen element with current element
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    }

    const start = function () {
      resetAll();
      checkHighestScore();
      // checkHighestScorePersonal();
      fullFillArray(99);
      arrayOneRadom.value = shuffle(arrayOne.value);
      isPlaying.value = true;
      startTimer();
    };

    const startAgain = function () {
      timeleft.value = null;
      arrayOneRadom.value = [];
      showResult.value = false;
      isPlaying.value = false;
      router.push({ path: "/" });
      // clearInterval(TimerOn);
      // window.location.reload(true);
    };

    const fullFillArray = (end) => {
      for (let i = 0; i <= end; i++) {
        arrayOne.value.push(i);
        arrayOneHundered.value.push(i);
      }
      // console.log(arrayOne.value);
    };

    const startTimer = function () {
      startTimerNum();
      timeleft.value = 60;
      wrongClicks.value = "";

      const TimerOn = setInterval(function () {
        if (timeleft.value <= 1) {
          clearInterval(TimerOn);
          wait(3).then(() => {
            console.log("czekalem 3 sekund");
          });
          console.log(
            "TIME IS UP, your rasult is: ",
            arrayOneHundered.value[0] - 1
          );
        }
        timeleft.value -= 1;
      }, 1000);
    };

    const checkIsCorrect = (element) => {
      let clickedElement = document.getElementById(element);

      if (element == arrayOneHundered.value[0]) {
        // console.log("🟩zgadza sie to ten element");
        arrayOneHundered.value.shift();
        clickedElement.classList.add("clicked");

        stopTimerNum();
        timerNumCollection.value.push(reactionTime.value);
        startTimerNum();
      } else {
        wrongClicks.value++;
        // console.log("🔴błąd", wrongClicks.value);
        clickedElement.classList.add("shake");
      }
    };

    const startTimerNum = () => {
      // start the timer, tick every 10ms
      reactionTime.value = 0;
      timerNum.value = setInterval(() => {
        reactionTime.value += 10;
        // console.log(reactionTime.value);
      }, 10);
    };

    const stopTimerNum = () => {
      // stop the timer
      clearInterval(timerNum.value);
    };

    const saveHighScore = async () => {
      const colRef = collection(db, databaseName.value);
      console.log("saveHighScore START");
      console.log(wynikNum.value);

      await addDoc(colRef, {
        score: wynikNum.value,
        createdAt: serverTimestamp(),
        helpUsed: helpUsed.value,
        // arrayOne: arrayOne.value,
        arrayOne: arrayOneRadom.value.toString(),
        uid: clicksUID.value,
        timerNumCollection: timerNumCollection.value.toString(),
        nickname: nickname.value,
      }).then(() => {
        console.log("saveHighScore END");
      });
    };

    class GameData {
      constructor(score, createdAt, helpUsed, arrayOne, timerNumCollection) {
        this.score = score;
        this.createdAt = createdAt;
        this.helpUsed = helpUsed;
        this.arrayOne = arrayOne;
        this.timerNumCollection = timerNumCollection;
      }
    }

    const saveHighScorePersonal = () => {
      // utworz i dodaj od razu obiekt z ta gra
      // score: wynikNum.value,
      // createdAt: serverTimestamp(),
      // helpUsed: helpUsed.value,
      // arrayOne: arrayOneRadom.value.toString(),
      // timerNumCollection: timerNumCollection.value.toString(),

      let timestamp = Date.now();

      const newGameData = new GameData(
        wynikNum.value,
        timestamp,
        helpUsed.value,
        arrayOneRadom.value,
        timerNumCollection.value
      );
      scorePersonal.value.push(newGameData);
      localStorage.setItem("clicksSCORE", JSON.stringify(scorePersonal.value)); //stringify object and store
      console.log(scorePersonal.value);

      // pobrac data z stoage
      // stworzyc nowy obiekt i spushowac do do pobranych danych
      // uploadowac data do storage
    };

    const saveNickname = async () => {
      const colRef = collection(db, "idNickname");
      console.log("saveNickname START");
      console.log(nickname.value);

      await setDoc(doc(db, "idNickname", clicksUID.value), {
        nickname: nickname.value,
      }).then(() => {
        console.log("saveNickname END");
      });
    };

    const checkHighestScore = () => {
      console.log(highScore.value);
      console.log("highScore length:", highScore.value.length);
      console.log(
        "highScore last from Top10 score:",
        highScore.value[highScore.value.length - 1].score
      );
      top10highestScoreUpTodate.value =
        highScore.value[highScore.value.length - 1].score;

      top1highestScoreUpTodate.value = highScore.value[0].score;
    };

    watchEffect(() => {
      clicksUID.value = localStorage.getItem("clicksUID");
      // console.log(clicksUID.value);
      wynikNum.value = arrayOneHundered.value[0] - 1;
      if (timeleft.value == 0 && wynikNum.value) {
        console.log("wynikNum.value", wynikNum.value);
        let lastClicked = wynikNum.value;
        let clickedElement = document.getElementById(lastClicked);
        clickedElement.classList.remove("clicked");
        clickedElement.classList.add("hiddensquare");

        console.log("END TIME");

        console.log("Numery wyklikane kolejnosc:", timerNumCollection.value); // 🟢 dlaczego po czasie to sie wykonuje 3x
        console.log("Numery all w kolejnosci:", ...arrayOneRadom.value); // 🟢 dlaczego po czasie to sie wykonuje 3x

        stopTimerNum();

        if (wynikNum.value < 0) {
          wynikNum.value == 0;
        }
      }
    });

    //
    //
    //
    //

    const checkIsTop10 = () => {
      if (
        wynikNum.value > top10highestScoreUpTodate.value &&
        wynikNum.value <= top1highestScoreUpTodate.value
      ) {
        console.log("🏆 You are in TOP-10");
        isTop10.value = true;
        saveHighScore();
      }
    };

    const checkIsTop1 = () => {
      if (wynikNum.value > top1highestScoreUpTodate.value) {
        console.log("🏆 You are The Best");
        isTop1.value = true;
        saveHighScore();
      }
    };

    const checkIsTheBestPersonal = () => {
      console.log(scorePersonal.value);
      console.log(scorePersonal.value.length);
      console.log(scorePersonal.value.length !== 0);

      console.log(wynikNum.value > scorePersonal.value[0].score);
      console.log(scorePersonal.value[0].score);
      if (
        // scorePersonal.value != undefined &&
        scorePersonal.value.length !== 0 &&
        wynikNum.value > scorePersonal.value[0].score
      ) {
        console.log(wynikNum.value);
        console.log(scorePersonal.value[0].score);
        console.log("🏆 Your personal Best");

        saveHighScorePersonal();
        isTheBestPersonal.value = true;
        console.log(scorePersonal.value[0].score);
      } else {
        saveHighScorePersonal();
      }
    };

    const resetAll = () => {
      isDisabled.value = true;
      arrayOne.value = [];
      arrayOneHundered.value = [];
      isTop1.value = false;
      isTop10.value = false;
      isTheBestPersonal.value = false;
      timerNumCollection.value = [];
    };

    const changeIcon = () => {
      isIcon.value = !isIcon.value;
    };

    const handleHelpBtn = (buttonId) => {
      console.log(arrayOneHundered.value[0]);
      document
        .getElementById(arrayOneHundered.value[0])
        .classList.add("highlighted");

      console.log("buttonId", buttonId);

      document.getElementById(buttonId).classList.remove("highlightedFast");
      document.getElementById(buttonId).classList.add("hiddensquare");
      document.getElementById(buttonId).disabled = true;
      helpUsed.value++;
      console.log("helpUsed:", helpUsed.value);
      // console.log(arrayOne.value);
    };

    const handleShowMore = (name) => {
      if (name == "personal") {
        showMoreMy.value = !showMoreMy.value;
      }
      if (name == "global") {
        showMoreGlobal.value = !showMoreGlobal.value;
      }
    };

    const handleSaveNickname = async () => {
      if (nickname) {
        localStorage.setItem("clicksNICK", nickname.value);
        await saveNickname().then(() => {
          showResult.value = true;
          checkIsTop10();
          checkIsTop1();
          checkIsTheBestPersonal();
        });
      }
    };

    const parseDate = (timestamp) => {
      // console.log(parse(timestamp, "T", new Date()));
      return formatDistanceToNow(parse(timestamp, "T", new Date()));
    };

    highScorePersonal.value = scorePersonal.value[-1];

    const startShare = () => {
      share({
        title: "🔥99clicks",
        text: "Your daily concentration challenge",
        url: location.href,
      });
    };

    return {
      start,
      arrayOneRadom,
      startTimer,
      timeleft,
      isPlaying,
      checkIsCorrect,

      checkIsCorrect,

      isLastClicked,
      isDisabled,
      startAgain,
      // isNewRecord,
      timerNumCollection,
      showResult,
      showEnterNickname,
      wynikNum,
      highScore,
      formatedHighScore,
      // formatedHighScoreSliced,
      // formatedHighScorePersonal,
      saveHighScorePersonal,
      scorePersonal,
      saveHighScore,
      checkIsTop10,
      isTop10,
      checkIsTop1,
      checkIsTheBestPersonal,
      isTheBestPersonal,
      isTop1,
      isIcon,
      changeIcon,
      arrayOneHundered,
      showMoreMy,
      showMoreGlobal,
      handleHelpBtn,
      isClicked,
      helpUsed,
      handleShowMore,
      nickname,
      handleSaveNickname,
      isNickname,
      startShare,
      parseDate,
      isFirstGame,
    };
  },
};
</script>

<style></style>
